<template>
  <div class="vg_wrapper">
   <el-card>
     <div>
      <el-form :inline="true" size="min" :model="searchForm" ref="searchForm" @keyup.enter.native="getDeptsNow()">
        <el-row>
          <el-col :md="8">
            <el-form-item label="员工编号：">
              <el-input size="small" v-model.trim="searchForm.stff_no" clearable placeholder="请填写员工编号"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="公司抬头：">
              <el-select v-model="searchForm.cptt_id" filterable placeholder="请选择公司抬头" clearable @visible-change="getCpttV1($event)" size="small">
                <el-option
                    v-for="item in cpttGroupOpt"
                    :key="item.cptt_id"
                  :label="item.cptt_name"
                  :value="item.cptt_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="员工岗位：">
              <el-input v-model="searchForm.stff_job"  placeholder="请填写员工岗位" size="small" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="8">
            <el-form-item label="部门名称：">
              <el-select v-model="searchForm.dept_id" placeholder="请选择部门" clearable @visible-change="getDeptV1($event)" size="small">
                <el-option
                    v-for="item in deptGroupOpt"
                    :key="item.dept_id"
                    :label="item.dept_name"
                    :value="item.dept_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="小组名称：">
              <el-select v-model="searchForm.dept_team_id" placeholder="请选择小组" clearable @visible-change="getDeptTeams" size="small">
                <el-option
                    v-for="item in deptTeamsGroupOpt"
                    :key="item.dept_team_id"
                    :label="item.dept_team_name"
                    :value="item.dept_team_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8" v-if="userInfo.acct_id==1">
            <el-form-item label="所属账套：" label-width="82px">
              <el-select
                v-model="searchForm.acct_id"
                filterable
                placeholder="请选择所属账套"
                clearable
                size="small"
                @change="getDeptsNow()"
              >
                <el-option
                  v-for="item in acctList"
                  :key="item.acct_id"
                  :label="item.acct_no"
                  :value="item.acct_id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="8">
            <el-form-item label="在职状态：">
              <div class="block vg_mr_32">
                <el-select v-model="searchForm.leave_time" placeholder="请选择员工在职状态" clearable size="small">
                  <el-option label="在职" :value=0></el-option>
                  <el-option label="离职" :value=1></el-option>
                </el-select>
              </div>
            </el-form-item>
          </el-col>
          <el-col :md="8">
            <el-form-item label="员工姓名：">
              <el-input size="small" v-model.trim="searchForm.stff_name" clearable placeholder="请填写员工姓名"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :md="14">
            <el-form-item label="入职时间：">
              <div class="block vg_mr_32">
                <el-date-picker
                    size="small"
                    v-model="searchForm.timeValue"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    >
                </el-date-picker>
              </div>
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="primary" icon="el-icon-search" @click="getDeptsNow()" class="vg_ml_16">查询</el-button>
              <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
     <div class="vd_button_group vg_mtb_16">
      <el-button type="primary" size="small" @click="addRow()" :disabled="!btn.add"><i class="el-icon-plus"></i> 新增</el-button>
      <!-- <el-button type="danger" size="small" @click="doDelete()" :disabled="!btn.delete"><i class="el-icon-delete"></i> 删除</el-button> -->
    </div>
     <el-row>
      <el-col :md="24">
        <el-table v-loading="loading" class="vg_pointer" ref="multiTable" :data="tableData" @selection-change="handleSelectionChange" @row-dblclick="dbClickJp" border style="width: 100%"  >
          <!-- <el-table-column type="selection" width="48"  align="center"/> -->
          <el-table-column label="员工编号" prop="stff_no" width="120"/>
          <el-table-column label="公司抬头" prop="cptt_name" show-overflow-tooltip/>
          <el-table-column label="部门名称" prop="dept_name" width="120"/>
          <el-table-column label="小组名称" prop="dept_team_name" width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.dept_team_name">{{scope.row.dept_team_name}}</span>  
              <span v-else class="notava">暂无小组</span>
            </template>
          </el-table-column>
          <el-table-column label="员工岗位" prop="stff_job" width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.stff_job">{{scope.row.stff_job}}</span>
              <span v-else class="vg_notava">暂无岗位</span>
            </template>
          </el-table-column>
          <el-table-column label="在职状态" prop="stff_leave_time" align="center" :formatter="formatDateLeft" width="120"/>
          <el-table-column label="员工姓名" prop="stff_name" width="120"/>
          <el-table-column label="身份证号" prop="stff_identity" >
            <template slot-scope="scope">
              <span v-if="scope.row.stff_identity">{{scope.row.stff_identity}}</span>
              <span v-else class="vg_notava">暂无身份证</span>
            </template> 
          </el-table-column>
          <el-table-column label="手机号" prop="stff_phone" >
            <template slot-scope="scope">
              <span v-if="scope.row.stff_phone">{{scope.row.stff_phone}}</span>
              <span v-else class="vg_notava">暂无手机号</span>
            </template> 
          </el-table-column>
          <el-table-column label="员工司龄" prop="stff_enter_time" :formatter="formatDateSen" width="120"/>
          <el-table-column label="聘用类型" prop="stff_labr_type"  width="120">
            <template slot-scope="scope">
              <span v-if="scope.row.stff_labr_type">{{scope.row.stff_labr_type}}</span>
              <span v-else class="vg_notava">暂无聘用类型</span>
            </template> 
          </el-table-column>
          <el-table-column label="入职时间" prop="stff_enter_time" :formatter="formatDate" show-overflow-tooltip=""/>
          <!-- <el-table-column label="录入人" prop="stff_name" show-overflow-tooltip :formatter="helper.personCombina"/> -->
          <el-table-column v-if="userInfo.acct_id===1" label="所属账套" prop="acct_no"></el-table-column>
        </el-table>
      </el-col>
    </el-row>
     <el-row>
      <el-col :md="24">
        <pubPagination :totalPage = 'totalPage' @changePageSearch = "changePageSearch" ref="pubPagination"></pubPagination>
      </el-col>
    </el-row>
   </el-card>
  </div>
</template>

<script>
import {get,post} from "@api/request";
import {stffAPI} from "@api/modules/staff";
import { acctAPI } from "@api/modules/acct";
// import {acctAPI} from "@api/modules/acct";
import {deptAPI} from "@api/modules/department";
import {cpttAPI} from "@api/modules/comptitle";
import {optnAPI} from "@api/modules/optn";
import pubPagination from "@/components/common/pubPagination";
export default {
  name: "StffList.vue",
  components: {
    pubPagination,
  },
  data(){
    return{
      searchForm: {
        stff_name: '',
        stff_no:null,
        cptt_id:null,
        dept_id:null,
        dept_team_id:null,
        stff_job:'',
        leave_time:null,
        acct_id:null,
        timeValue:{
          startTime:null,
          endTime:null
        }
      },
      cpttGroupOpt:[],
      deptGroupOpt:[],
      deptTeamsGroupOpt:[],
      stffJobGroupOpt: [],
      currentPage: 1,
      totalPage:0,
      tableData: [],
      multiSelection: [],
      btn:{},
      loading: true,
      userInfo:null,
      acctList:[]
    }
  },
  created() {
    this.initData()
  },
  watch:{
    $route(to,from){
      if(from.path === '/stff_add' || from.path === '/stff_edit'){
        this.initData()
      }
    }
  },
  methods:{
    initData(){
      this.loading = true 
      this.getStffsList()
      this.getUserInfo()
      if(this.userInfo.acct_id==1){
        this.getAcct()
      }
    },
    getUserInfo(){
      this.userInfo = this.$cookies.get('userInfo')
    },
    // 获取员工信息列表
    getStffsList(){
      const timeNewVal = this.helper.getTime(this.searchForm.timeValue)
      const searchBody = {
        stff_name: this.searchForm.stff_name,
        stff_no: this.searchForm.stff_no,
        cptt_id: this.searchForm.cptt_id,
        dept_id: this.searchForm.dept_id,
        dept_team_id: this.searchForm.dept_team_id,
        stff_job: this.searchForm.stff_job,
        leave_time: this.searchForm.leave_time,
        acct_id:this.searchForm.acct_id,
        page_no: this.currentPage,
        start_time: timeNewVal.startTime,
        end_time: timeNewVal.endTime,
      }
      get(stffAPI.getStffs,searchBody)
      .then(res=>{
        if(res.data.code === 0){
          this.tableData = res.data.data.list
          this.totalPage = res.data.data.total
          this.btn = res.data.data.btn;
          setTimeout(()=>{
          this.loading = false
          },500)
        } else {
          let mg = res.data.msg;
          let tp = 'error';
          this.$message({message:mg,type:tp});
        }
      }).catch(res =>{
        let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
      })
    },
     //获取审批账套
    getAcct() {
      get(acctAPI.getAllAcctsV1)
        .then((res) => {
          if (res.data.code == 0) {
            this.acctList = res.data.data;
            return;
          }
          return this.$message.error(res.data.msg);
        })
        .catch((res) => {
          let mg = res.data.msg;
          let tp = "error";
          this.$message({ message: mg, type: tp });
        });
    },
    // 获取cpttId
    getCpttV1(flag){
      get(cpttAPI.getAllCpttsV1)
          .then(res=>{
            if(res.data.code === 0){
              this.cpttGroupOpt = res.data.data;
            }else{
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
            }
          }).catch(res =>{
            let mg = res.data.msg;
            let tp = 'error';
            this.$message({message: mg, type: tp});
          })
    },
    // 获取deptId
    getDeptV1(flag){
      if(flag === true && this.deptGroupOpt.length === 0){
        get(deptAPI.getAllDeptsV1)
            .then(res=>{
              if(res.data.code === 0){
                this.deptGroupOpt = res.data.data
              }else{
                let mg = res.data.msg;
                let tp = 'error';
                this.$message({message:mg,type:tp});
              }
            }).catch(res =>{
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
            })
      }
    },
    // 获取deptTeamId
    getDeptTeams(flag){
      if(flag === true && this.deptTeamsGroupOpt.length === 0){
        get(deptAPI.getAllDeptTeams)
           .then(res=>{
             if(res.data.code === 0){
               this.deptTeamsGroupOpt = res.data.data
             }else{
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
             }
           }).catch(res =>{
             let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
           })
      }
    },
    //获取员工工种
    getStffJob(flag){
      console.log('kklljjjj')
      // if(flag === true && this.stffJobGroupOpt.length === 0){
        get(optnAPI.getAllContent,{perm_id : 10001})
            .then(res=>{
              if(res.data.code === 0) {
                this.stffJobGroupOpt = res.data.data
              }else{
                let mg = res.data.msg;
                let tp = 'error';
                this.$message({message:mg,type:tp});
              }
            }).catch(res =>{
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message: mg, type: tp});
            })
      // }
    },
    // 刷新
    buttonRefresh(){
      this.searchForm = {
        stff_name: '',
        stff_no:null,
        cptt_id:null,
        dept_id:null,
        dept_team_id:null,
        stff_job:'',
        leave_time:null,
        acct_id:null,
        timeValue:{
          startTime:null,
          endTime:null
        }
      };
      this.loading = true
      this.$refs.pubPagination.currentPage = 1
      this.currentPage = 1
      this.initData()
    },
    // 时间转换
    formatDate(row) {
      if(row.stff_enter_time){
        return this.helper.toTimeDay(row.stff_enter_time)
      }else{
        return '暂无'
      }
     
    },
    // 员工离职
    formatDateLeft(row) {
      if(row.stff_leave_time === 0){
        return '在职'
      } else {
        return this.helper.toTimeDay(row.stff_leave_time)+'离职'
      }
    },
    // 员工司龄
    formatDateSen(row){
      if(row.stff_enter_time){
        return this.helper.toSeniority(row.stff_enter_time,row.stff_leave_time)
      }else{
        return '暂无'
      }
    },
    // // 聘用类型
    // formatEmpType(row){
    //   if(row.stff_labr_cont === 5){
    //     return '试用'
    //   } else if(row.stff_labr_cont === 1){
    //     return '返聘'
    //   } else if(row.stff_labr_cont === 2){
    //     return '实习'
    //   } else if(row.stff_labr_cont === 3){
    //     return '劳务'
    //   } else if(row.stff_labr_cont === 4){
    //     return '正式'
    //   } else {
    //     return '无生效合同'
    //   }
    // },
    // 查询
    getDeptsNow(){
      this.$refs.pubPagination.currentPage = 1
      this.currentPage = 1
      this.loading = true 
      this.getStffsList()
    },
    // 分页查询
    changePageSearch(val){
      this.loading = true
      this.currentPage = val
      this.getStffsList()
    },
    // 多选
    handleSelectionChange(val){
      this.multiSelection = val
    },
    // 双击编辑
    dbClickJp(row){
      this.jump('/stff_edit',{perm_id:this.$route.query.perm_id,form_id:row.stff_id})
    },
    // 新增
    addRow(){
      this.jump('/stff_add',{perm_id:this.$route.query.perm_id})
    },
    //删除提示
    doDelete(){
      if(this.multiSelection.length === 0){
        this.$message({type:'warning',message:'请至少选择一条数据！'})
      } else {
        this.mBox()
      }
    },
    mBox() {
      this.$confirm('确定删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.delRow()
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
        this.$refs.multiTable.clearSelection();
      });
    },
    // 删除
    delRow(){
      const ids = []
      this.multiSelection.map((item)=>{
        ids.push(item.stff_id)
      })
    
        post(stffAPI.deletStffByIds,{'stff_id_list':ids})
            .then(res=>{
              if(res.data.code === 0){
                let mg = res.data.msg;
                let tp = 'success';
                this.$message({message:mg,type:tp});
                if(ids.length === this.tableData.length){
                if(this.currentPage > 1){
                  this.currentPage = this.currentPage -1
                  this.$refs.pubPagination.currentPage = this.currentPage
                }
              }
                this.initData()
              } else {
                let mg = res.data.msg;
                let tp = 'error';
                this.$message({message:mg,type:tp});
                this.initData()
              }
            })
            .catch(res=>{
              let mg = res.data.msg;
              let tp = 'error';
              this.$message({message:mg,type:tp});
              this.initData()
            })
    },
  },
}
</script>

<style scoped lang="scss">

</style>